async function parseJSON(response: Response) {
  return await response.json().then((json) => ({
    status: response.status,
    ok: response.ok,
    json,
  }));
}

export const fetchRegionsForEmail = async (email: string) => {
  const code = import.meta.env.VITE_LOGIN_API_CODE;
  const host = window.location.hostname;
  const orgRegEnv = host.replace(".ctnxt.clootrack.com", "").split(".");

  let newHost;
  switch (orgRegEnv.length) {
    // desired url : dev.ctnxt.clootrack.com
    case 1:
      // eg: ['dev']
      newHost = orgRegEnv.join("");
      break;
    case 2:
      // eg: ['cus', 'dev']
      newHost = orgRegEnv.slice(1).join(".");
      break;
    case 3:
      // eg: ['org2', 'cus', 'dev']
      newHost = orgRegEnv.slice(1).join(".");
      break;
    default:
      throw new Error(`Invalid orgRegEnv length: ${orgRegEnv.length}`);
  }

  const response = await fetch(
    `https://backend.${newHost}.ctnxt.clootrack.com/api/retrieve_regions?email=${email}&code=${code}`
  ).then(parseJSON);
  return response;
};
